import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #ebebff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-out;

  .pulse {
    height: 4.8rem;
    width: 4.8rem;
    background-color: #0000fe;
    box-shadow: 0px 0px 50px #0000fe50;
    border-radius: 50%;
    animation: ${pulse} 1.5s ease-out infinite;
  }
`;

const Loader = () => {
  return (
    <Wrapper>
      <div className="pulse"></div>
    </Wrapper>
  );
};

export default Loader;
