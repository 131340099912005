import React, { Suspense, lazy, useState, useEffect } from "react";
import Loader from "./components/global/Loader";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";
import theme from "./utils/theme";
import exitIntent from 'exit-intent';
import ExitModal from './components/exit_intent/exit_modal'

const ThankYou = lazy(() => import("./pages/ThankYou"));
const Landing = lazy(() => import("./pages/Landing"));
// const Careers = lazy(() => import("./pages/careers/Careers"));
const GetProposal = lazy(() => import("./pages/getProposal"));
const ContactUs = lazy(() => import("./pages/Contact"));
const Privacy = lazy(() => import("./pages/Privacy"));
const About = lazy(() => import("./pages/About"));
const WebDev = lazy(() => import("./pages/services/WebDev"));
const Heroshe = lazy(() => import("./pages/case_studies/Heroshe"));
const Anike = lazy(() => import("./pages/case_studies/Anike"));
const Mygst = lazy(() => import("./pages/case_studies/Mygst"));
const UIUXDesign = lazy(() => import("./pages/services/UIUXDesign"));
const GoogleSearchAds = lazy(() => import("./pages/services/GoogleSearchAds"));
const SearchEngineOptimization = lazy(() =>
  import("./pages/services/SearchEngineOptimization")
);
const SocialMediaMarketing = lazy(() =>
  import("./pages/services/SocialMediaMarketing")
);
const SalesFunnelManagement = lazy(() =>
  import("./pages/services/SalesFunnelManagement")
);
const WordPressWebsiteCreation = lazy(() =>
  import("./pages/services/WordPressWebsiteCreation")
);
const Consultation = lazy(() => import("./consultation/Consutation"));

const AUTO_mobile = lazy(() => import("./pages/sectors/AUTO_mobile"));
const B2B_page = lazy(() => import("./pages/sectors/B2B_page"));
const B2C_general = lazy(() => import("./pages/sectors/B2C_general"));
const HEALTH_sector = lazy(() => import("./pages/sectors/HEALTH_sector"));
const LAW_firms = lazy(() => import("./pages/sectors/LAW_firms"));
const LogisticsAnd_Shipping = lazy(() =>
  import("./pages/sectors/LogisticsAnd_Shipping")
);
const E_commerce = lazy(() => import("./pages/sectors/E_commerce"));
const REAL_estate = lazy(() => import("./pages/sectors/REAL_estate"));
const FINTECH_page = lazy(() => import("./pages/sectors/FINTECH_page"));
const ContactUs_sectors = lazy(() => import("./pages/sectors/ContactUs"));

// SECTORS COPY
const AUTO_mobile_copy = lazy(() => import("./pages/sectors_copy/AUTO_mobile"));
const B2B_page_copy = lazy(() => import("./pages/sectors_copy/B2B_page"));
const B2C_general_copy = lazy(() => import("./pages/sectors_copy/B2C_general"));
const HEALTH_sector_copy = lazy(() =>
  import("./pages/sectors_copy/HEALTH_sector")
);
const LAW_firms_copy = lazy(() => import("./pages/sectors_copy/LAW_firms"));
const LogisticsAnd_Shipping_copy = lazy(() =>
  import("./pages/sectors_copy/LogisticsAnd_Shipping")
);
const E_commerce_copy = lazy(() => import("./pages/sectors_copy/E_commerce"));
const REAL_estate_copy = lazy(() => import("./pages/sectors_copy/REAL_estate"));
const FINTECH_page_copy = lazy(() =>
  import("./pages/sectors_copy/FINTECH_page")
);

Aos.init({ duration: 1000 });

function App() {
  const [modalPopup, setModalPopup] = useState(false)
  

  useEffect(() => {
    const removeExitIntent = exitIntent({
      threshold: 50,
      maxDisplays: 6,
      eventThrottle: 100,
      onExitIntent: () => {
        if (document.cookie.indexOf("exit_modal_seen=true") > -1) {
          return;
        }
  
        let expiryDate = new Date(Date.now())
        expiryDate.setMinutes(expiryDate.getMinutes() + 1);
        document.cookie=`exit_modal_seen=true; expires=${expiryDate.toUTCString()}`
  
        return setModalPopup(true);
      }    
    })

    return () => removeExitIntent()
    // eslint-disable-next-line
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ThemeProvider theme={theme}>
          <ExitModal trigger={modalPopup} setTrigger={setModalPopup}/>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/get-proposal" component={GetProposal} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/Privacy-policy" component={Privacy} />
            <Route path="/about-us" component={About} />

            {/* Case Studies */}
            <Route path="/heroshe" component={Heroshe} />
            <Route path="/anike-natural" component={Anike} />
            <Route path="/myGSTzone" component={Mygst} />

            {/* Services */}
            <Route path="/google-search-ads" component={GoogleSearchAds} />
            <Route
              path="/sales-funnel-management"
              component={SalesFunnelManagement}
            />
            <Route
              path="/search-engine-optimization"
              component={SearchEngineOptimization}
            />
            <Route
              path="/social-media-marketing"
              component={SocialMediaMarketing}
            />
            <Route path="/ui-ux-design" component={UIUXDesign} />
            <Route path="/web-development" component={WebDev} />
            <Route
              path="/wordpress-website-creation"
              component={WordPressWebsiteCreation}
            />

            {/* Consultation */}
            <Route exact path="/consultation" component={Consultation} />

            {/* Careers */}
            {/* <Route path="/careers" component={Careers} /> */}

            {/* Sectors */}
            <Route exact path="/e-commerce" component={E_commerce} />
            <Route exact path="/business-to-customer" component={B2C_general} />
            <Route exact path="/real-estate" component={REAL_estate} />
            <Route exact path="/law-firms" component={LAW_firms} />
            <Route exact path="/health-care" component={HEALTH_sector} />
            <Route
              exact
              path="/logistics-and-shipping"
              component={LogisticsAnd_Shipping}
            />
            <Route exact path="/automobile" component={AUTO_mobile} />
            <Route exact path="/business-to-business" component={B2B_page} />
            <Route exact path="/fintech" component={FINTECH_page} />
            <Route path="/proposal/contact-us" component={ContactUs_sectors} />

            {/* Sectors copy */}
            <Route
              exact
              path="/sectors/e-commerce"
              component={E_commerce_copy}
            />
            <Route
              exact
              path="/sectors/business-to-customer"
              component={B2C_general_copy}
            />
            <Route
              exact
              path="/sectors/real-estate"
              component={REAL_estate_copy}
            />
            <Route exact path="/sectors/law-firms" component={LAW_firms_copy} />
            <Route
              exact
              path="/sectors/health-care"
              component={HEALTH_sector_copy}
            />
            <Route
              exact
              path="/sectors/logistics-and-shipping"
              component={LogisticsAnd_Shipping_copy}
            />
            <Route
              exact
              path="/sectors/automobile"
              component={AUTO_mobile_copy}
            />
            <Route
              exact
              path="/sectors/business-to-business"
              component={B2B_page_copy}
            />
            <Route
              exact
              path="/sectors/fintech"
              component={FINTECH_page_copy}
            />

            {/* Thank you page */}
            <Route path="/thankyou" component={ThankYou} />

            {/* No match */}
            <Route component={() => <Redirect to="/" />} />
          </Switch>
        </ThemeProvider>
      </Router>
    </Suspense>
  );
}

export default App;
