import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.bg !== "secondary" ? "1.2rem" : "4rem")};
  height: 6rem;
  width: fit-content;
  padding: 0 3.6rem;
  background: ${(props) =>
    props.bg === "brown"
      ? "#EBDDC2"
      : props.bg === "#ffffff" || props.bg === "#EBDDC2" || props.bg === "white"
      ? "#262f73"
      : "white"};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  transition: background 250ms ease-in;

  &:hover {
    background: linear-gradient(90.41deg, #0000fe 0.36%, #0000fe 99.69%);
    color: #ffffff;
  }

  &:focus {
    background-color: #6666ff;
    color: #ffffff;
  }
`;

const Button = ({ className, text, bg, color, as, href, disabled }) => {
  return (
    <Wrapper as={as} href={href} bg={bg} color={color} className={className} disabled={disabled}>
      {text}
    </Wrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
