import React from "react";
import styled from "styled-components";
import Button from "../global/Button";
import Container from "../global/Container";
import {exit_intent_img, close} from "../../assets";

const Wrapper = styled.section`
  .modalInfo{
    margin: 48px;
    height: 100%;
    width: 100%;
    display: flex;
  }
  .details-section{
    margin: 133px 0;
    max-width: 292px;
  }
  .container .modal {
    z-index: 99;
    width: 887px;
    height: 486px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    position: relative;
    animation: animate 0.3s;
  }
  .exit-btn {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 24px;
    right: 48px;
    padding: 6px;
    background: #F2F3F7;
    color: black;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
  }
  .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    z-index:99;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
  }
  .first-section{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }

  .second-section{
    margin-top: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color:#747779;
  }
  .claim-btn{
    margin-top: 48px;
    align-items: center;
    padding: 20px 24px;
    gap: 10px;
    color:#FEFBF2;
    font-size: 16px;
    width: 199px;
    height: 56px;
    background: #262F73;
    border-radius: 8px;
  }
  .img-section{
    margin: 60px 0 24px 55px;
    height:402px;
    background: url(${exit_intent_img});   
    width:444px;
    background-size:cover;
  }

  @media screen and (max-width: 768px) {
    .img-section{
       display:none;
      }
      .details-section{
        text-align:center;
      }
  }
`;

const PopUp = (props) => {
  return (props.trigger) ? (
    <Container>
        <Wrapper>
            <div className="container">
                <div className="modal">
                    <div className="modalInfo"> 
                        <button className="exit-btn" >
                            <img src={close} onClick={() => props.setTrigger(false)}></img>
                        </button>

                        <div className="details-section">
                            <p className="first-section displayMediumBold">"Get a customized digital marketing plan for your business"</p>
                            <p className="second-section">Our Experts will help put together a digital marketing plan you can execute for your business.</p>
                            
                            <Button
                                as="a"
                                bg="white"
                                color="white"
                                text="Claim now"
                                className="textSmallBold claim-btn"
                                href="/proposal/contact-us"
                                />
                            {/* <button className="claim-btn" >Claim now</button> */}
                        </div>
                        <div className="img-section"></div>
                    </div>
                </div> 
            </div>
        </Wrapper>
    </Container>
  ) : ''
};

export default PopUp;
