const theme = {
  colors: {
    primary: "#0000fe",
    black: "#000000",
    white: "#ffffff",
    accent: "#ffd700",
    dark_1: "#212129",
    gradients: [
      "linear-gradient(90.41deg, #46EAAF 0.36%, #1B08F2 99.69%)",
      "linear-gradient(90deg, #FFE259 0%, #FFA751 94.6%)",
      "linear-gradient(90deg, #CC2B5E 0%, #753A88 94.6%)",
    ],
    buttonBorder: "#eff0f6",
    filterPrimary:
      "brightness(0) saturate(100%) invert(8%) sepia(100%) saturate(7483%) hue-rotate(248deg) brightness(91%) contrast(143%)",
    filterWhite:
      "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(233deg) brightness(111%) contrast(101%);",
    anike_bg: "#FFECE2",
    heroshe_bg: "#33CCCD",
    myGst_bg: "#28009B",
  },
};

export default theme;
